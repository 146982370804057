//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      isBoss: this.$store.state.currentAdmin.isBoss,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        source: null,
        commentType: ""
      },
      comments: []
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getComments();
  },
  mounted: function mounted() {},
  methods: {
    clearSearch: function clearSearch() {
      this.pagination = {
        current: 1,
        size: 10,
        total: 0,
        source: null,
        commentType: ""
      };
      this.getComments();
    },
    getComments: function getComments() {
      var _this = this;

      var url = "";

      if (this.isBoss) {
        url = "/admin/comment/boss/list";
      } else {
        url = "/admin/comment/user/list";
      }

      this.$http.post(this.$constant.baseURL + url, this.pagination, true).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.comments = res.data.records;
          _this.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getComments();
    },
    searchComments: function searchComments() {
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.getComments();
    },
    handleDelete: function handleDelete(item) {
      var _this2 = this;

      var url = "";

      if (this.isBoss) {
        url = "/admin/comment/boss/deleteComment";
      } else {
        url = "/admin/comment/user/deleteComment";
      }

      this.$confirm('删除评论后，所有该评论的回复均不可见。确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this2.$http.get(_this2.$constant.baseURL + url, {
          id: item.id
        }, true).then(function (res) {
          _this2.pagination.current = 1;

          _this2.getComments();

          _this2.$message({
            message: "删除成功！",
            type: "success"
          });
        }).catch(function (error) {
          _this2.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    }
  }
};